<template>
  <main id="content" role="main">
    <section class="visual_intro ani_intro"
      v-if="fix.id !== ''">
      <div class="visual_wall"
        v-bind:style="{ backgroundImage: 'url(' + fix.topFixImageUrl + ')' }">
      </div>
        <router-link :to="{ name: 'ContentsDetail', params: { ncNo: fix.id} }"
          class="main_article">
          <div class="article_title">
            <div class="category" v-text="fix.contentCategoryName"></div>
            <div class="title" v-text="fix.title"></div>
          </div>
        </router-link>
    </section>
    <section class="report_group">
      <div class="news-tab-menu">
        <ul>
          <li class="on"><router-link to="/contents/list">NEWS</router-link></li>
          <li><router-link to="/letter/list">NEWSLETTER</router-link></li>
        </ul>
      </div>
      <section class="report_box">
            <div class="box_title">
                <h2 class="title">NEWS</h2>
                <p class="description">{{$t('content.news.list.news_desc')}}</p>
            </div>
            <div class="news_area">
                <div class="news_order">
                    <div class="order_flag">
                        <button role="button" aria-haspopup="listbox" v-text="rangeText"
                          :aria-expanded="rangeShow" class="current" @click="rangeCol"></button>
                        <ul class="flag_list" role="listbox">
                            <li class="flag_item" role="presentation">
                                <button class="flag" role="option"
                                  @click="rangeCol" :aria-selected="range[0]"
                                  v-html="$t('content.news.list.sort.dates')">
                                </button>
                            </li>


                            <li class="flag_item" role="presentation">
                                <button class="flag" role="option"
                                  @click="rangeCol" :aria-selected="range[1]"
                                  v-html="$t('content.news.list.sort.popularity')">
                                </button>
                            </li>
                            <li class="flag_item" role="presentation">
                                <button class="flag" role="option"
                                  @click="rangeCol" :aria-selected="range[2]"
                                  v-html="$t('content.news.list.sort.registrated')">
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="order_tab" role="tablist">
                        <button class="tab" role="tab"
                          @click="cateCol($event, 0)" :aria-selected="cate[0]">ALL</button>
                        <button class="tab" role="tab"
                          @click="cateCol($event, 1)" :aria-selected="cate[1]">PEOPLE</button>
                        <button class="tab" role="tab"
                          @click="cateCol($event, 2)" :aria-selected="cate[2]">BUSINESS INSIGHT</button>
                        <!-- <button class="tab" role="tab"
                          @click="cateCol($event, 3)" :aria-selected="cate[3]">INSIGHT</button> -->
                        <button class="tab" role="tab"
                          @click="cateCol($event, 4)" :aria-selected="cate[4]">TECH</button>
                        <button class="tab" role="tab"
                          @click="cateCol($event, 5)" :aria-selected="cate[5]">FINANCE</button>
                        <button class="tab" role="tab"
                          @click="cateCol($event, 6)" :aria-selected="cate[6]">LIFESTYLE</button>
                    </div>
                </div>
                <section class="news-list-section">
                  <ul class="news_list" v-if="list.length > 0">
                      <li class="news_item"
                        v-for="(contnets, index) in list" :key="index">
                          <router-link :to="{ name: 'ContentsDetail', params: { ncNo: contnets.id} }" class="news">
                            <div class="thumb" >
                              <template v-if="contnets.youtubeUrl">
                                <img :src="`https://img.youtube.com/vi/${youtubeUrl(contnets.youtubeUrl)}/0.jpg`"
                                  alt="" class="img_thumb" data-aos="fade-up">
                              </template>
                              <template v-else-if="contnets.thumbnailFileUrl">
                                <img
                                    data-aos="fade-up"
                                    :src="contnets.thumbnailFileUrl"
                                    alt="" class="img_thumb">
                              </template>
                            </div>
                            <strong class="category" v-text="contnets.contentCategoryName"></strong>
                            <strong class="title" v-text="contnets.title"></strong>
                              <div class="update" v-text="datafilter(contnets.insertDate)"></div>
                          </router-link>
                      </li>
                  </ul>
                  <ul class="news_list" v-show="dataVisible">
                    <div class="no-list-wrap">
                      <div class="no-list">
                        <h6 data-aos="flip-left" class="aos-init aos-animate"
                        v-html="$t('content.news.list.no_content')">
                        </h6>
                      </div>
                    </div>
                  </ul>
                </section>
                <div class="news_more" v-show="moreVisible" @click="contentsList">
                    <button class="btn_more">
                      <span class="ico_more"></span>
                      <span class="txt_more">{{$t('content.news.list.more')}}</span>
                    </button>
                </div>
            </div>
        </section>
    </section>
  </main>
</template>

<script>

import { mixin as mixinHelperUtils, mixinMotion, CONTENT_CATEGORY } from '@/common/helperUtils';
import { APIs, FilePaths } from '@/common/portalApi';

export default {
  name: 'ContentsList',
  mixins: [mixinHelperUtils, mixinMotion],
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      filePaths: FilePaths,
      helperUtils: mixinHelperUtils.methods,
      mainbgImg: '',
      cate: [true, false, false, false, false, false, false],
      range: [true, false, false],
      rangeShow: false,
      rangeText: this.$t('content.news.list.sort.dates'),
      moreVisible: false,
      dataVisible: false,
      totalcount: 0,
      count: 0,
      fix: {
        id: '',
        contentCategoryName: '',
        title: '',
        topFixImageUrl: [],
      },
      list: [],
      seach: {
        page: 1,
        size: 12, //12,
        order: 'content.id desc',
        data: {
          isTmp: 0,
          contentCategory: null,
          isShow: 1,
          isPortal: 1
        }
      },
    };
  },
  mounted() {
    this.contentsFix();
    this.contentsList();
    // const elVisual = document.getElementsByClassName('visual_intro');
    // elVisual[0].className += ' ani_intro';
  },
  methods: {
    init() {
      this.list = [];
      this.seach.page = 1;
      this.contentsList();
    },
    contentsFix() {
      this.portalApiClient({
        url: APIs.CONTENTS_LIST,
        data: {
          data: {
            isTmp: false,
            isShow: true,
            isTopFixed: true
          },
        },
      }).then(({ status, data }) => {
        if (data.result) {
          const fix = data.list.slice(0, 1)[0];
          Object.assign(this.fix, fix);
          console.log(fix);
          // if (fix && fix.file_info) {
          //   this.mainbgImg = fix.file_info.filter((v) => v.code_attach_cata === 'AF302')[0].attach_file_path;
          // }
        }
      });
    },
    youtubeUrl(url) {
      // eslint-disable-next-line
      const match = url.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/);
      return match === null ? '' : match[7];
    },
    contentsList() {
      this.portalApiClient({
        url: APIs.CONTENTS_LIST,
        data: this.seach,
      }).then(({ status, data }) => {
        if (data.result) {
          this.list = this.list.concat(data.list);
          this.totalcount = data.totalCount;
          this.count = data.count;
          this.seach.page += 1;
          
          // 더보기 버튼 활성화
          if (this.list.length >= this.count) {
            this.moreVisible = false;
          } else {
            this.moreVisible = true;
          }
          if (this.list.length > 0) {
            this.dataVisible = false;
          } else {
            this.dataVisible = true;
          }
        }
      });
    },
    datafilter(date) {
      return this.$moment(date).format('YYYY.MM.DD');
    },
    /**
    * 카테고리별 조회
    */
    cateCol(e, index) {
      const category = CONTENT_CATEGORY[e.target.innerHTML]
      this.cate.fill(false);
      this.cate[index] = true;
      this.seach.data.contentCategory = category;
      this.init();
    },
    /**
    * 순서 정렬
    */
    rangeCol(e) {
      this.rangeShow = !this.rangeShow;
      if (e.target.classList.value !== 'flag') return;
      if (e.target.innerHTML === this.$t('content.news.list.sort.dates')) {
        this.range = [true, false, false];
        this.rangeText = e.target.innerHTML;
        this.seach.sortType = 'latest';
        this.seach.order = 'content.id desc';
      } else if (e.target.innerHTML === this.$t('content.news.list.sort.popularity')) {
        this.range = [false, true, false];
        this.rangeText = e.target.innerHTML;
        this.seach.order = 'content.hit_count desc';
      } else if (e.target.innerHTML === this.$t('content.news.list.sort.registrated')) {
        this.range = [false, false, true];
        this.rangeText = e.target.innerHTML;
        this.seach.sortType = 'register';
        this.seach.order = 'content.id asc';
      }
      this.init();
    },
    /**
    * 순서 활성화 col
    */
    // checkGuban(index) {
    //   this.cate.forEach((v, i) => {
    //     if (index === i) {
    //       this.cate[i] = true;
    //     } else {
    //       this.cate[i] = false;
    //     }
    //   });
    //   console.log(this.cate);
    // },
  },
};
</script>
